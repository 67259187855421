import React from 'react';
import "./Home.css";


export default function Home() {

    return (
        <>
            <div className='home-container'>
                <h2>Welcome to the Watering Hole!</h2>
            </div>
        </>
    )
}